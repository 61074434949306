import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import * as dat from "dat.gui";
import gsap from "gsap";

import "./ps-control-styles.css";

const canvas = document.createElement("canvas");
document.body.appendChild(canvas);

// Sizes
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

const getCameraPositionZ = () => {
  if (window.innerWidth < 992) {
    return 10;
  } else if (window.innerWidth < 1400) {
    return 4.5;
  } else {
    return 3;
  }
};

window.addEventListener("resize", () => {
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  camera.aspect = sizes.width / sizes.height;
  camera.position.z = getCameraPositionZ();
  camera.updateProjectionMatrix();
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

window.addEventListener("dblclick", () => {
  const fullscreenElement =
    document.fullscreenElement || document.webkitFullscreenElement;
  if (!fullscreenElement) {
    if (canvas.requestFullscreen) {
      canvas.requestFullscreen();
    } else if (canvas.webkitRequestFullscreen) {
      canvas.webkitRequestFullscreen();
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }
});

//Scene
const scene = new THREE.Scene();

//Camera
const camera = new THREE.PerspectiveCamera(
  75,
  sizes.width / sizes.height,
  0.1,
  100
);

// Lights
const rectAreaLight = new THREE.RectAreaLight(0xffffff, 1.2, 5, 5);
rectAreaLight.lookAt(new THREE.Vector3());
rectAreaLight.position.set(0, 0, 0.93);
scene.add(rectAreaLight);

// Objects

const getShapeMaterialOfColor = (color) => {
  return new THREE.MeshStandardMaterial({ color, metalness: 0.1 });
};

const cross = new THREE.Group();
const cylinderMaterial = getShapeMaterialOfColor(0x7cb2e8);
const cylinderA = new THREE.Mesh(
  new THREE.CylinderGeometry(0.1, 0.1, 1.1, 64),
  cylinderMaterial
);
cylinderA.rotation.z = Math.PI * 0.75;
const cylinderB = new THREE.Mesh(
  new THREE.CylinderGeometry(0.1, 0.1, 1.1, 64),
  cylinderMaterial
);
cylinderB.rotation.z = Math.PI * 0.25;
cross.add(cylinderA, cylinderB);
cross.position.x = -2.4;

const circle = new THREE.Mesh(
  new THREE.TorusGeometry(0.5, 0.1, 30, 64),
  getShapeMaterialOfColor(0xff6666)
);
circle.position.x = -0.8;

const square = new THREE.Mesh(
  new THREE.TorusGeometry(0.6, 0.1, 64, 4),
  getShapeMaterialOfColor(0xff69f8)
);
square.rotation.z = Math.PI * 0.25;
square.position.x = 0.8;

const triangle = new THREE.Mesh(
  new THREE.TorusGeometry(0.6, 0.1, 64, 3),
  getShapeMaterialOfColor(0x40e2a0)
);
triangle.rotation.z = Math.PI * 0.5;
triangle.position.x = 2.4;
triangle.position.y = -0.15;

const plane = new THREE.Mesh(
  new THREE.PlaneGeometry(7, 7),
  new THREE.MeshStandardMaterial({
    roughness: 0.4,
    metalness: 0.1,
    color: 0x0070d1,
  })
);
plane.rotation.x = -Math.PI * 0.5;
plane.position.y = -0.65;

scene.add(cross, square, circle, triangle, plane);

camera.position.z = getCameraPositionZ();

// Intro Animations
gsap.from(cross.rotation, { duration: 1, z: -Math.PI * 2 });
gsap.from(cross.position, { duration: 1, z: -4, ease: "back.out(1.7)" });
gsap.from(circle.position, { duration: 1.2, z: -5, ease: "back.out(1.7)" });
gsap.from(square.position, { duration: 1.4, z: -6, ease: "back.out(1.7)" });
gsap.from(triangle.position, { duration: 1.6, z: -7, ease: "back.out(1.7)" });
gsap.from(rectAreaLight.position, {
  duration: 4,
  x: -10,
  ease: "elastic.out(1, 0.75)",
});
gsap.from(camera.position, { duration: 3, y: 2, z: camera.position.z * 2 });

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;
controls.maxPolarAngle = 1.7;

//Renderer
const renderer = new THREE.WebGLRenderer({ canvas });
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.render(scene, camera);

// Clock
let clock = new THREE.Clock();

// Animations
const tick = () => {
  const elapsedTime = clock.getElapsedTime();

  // Update Objects
  cross.rotation.y = -0.1 * elapsedTime;
  circle.rotation.y = 0.13 * elapsedTime;
  square.rotation.y = -0.1 * elapsedTime;
  triangle.rotation.y = 0.13 * elapsedTime;

  // Update controls
  controls.update();

  // Render
  renderer.render(scene, camera);

  window.requestAnimationFrame(tick);
};

tick();

//Debug
// const gui = new dat.GUI();
// gui.hide();

// gui.add(controls, "enabled").name("Orbit controls");
// gui.add(rectAreaLight, "intensity").min(-1).max(4).step(0.001);
// gui.add(rectAreaLight.position, "z").min(-1).max(4).step(0.001);
// gui.add(rectAreaLight.position, "x").min(-1).max(4).step(0.001);
// gui.add(rectAreaLight.position, "y").min(-3).max(4).step(0.001);
// gui.add(triangle.position, "x").min(-3).max(4).step(0.001);
// gui.add(triangle.position, "y").min(-3).max(4).step(0.001);
// gui.add(square.position, "x").min(-3).max(4).step(0.001);
// gui.add(square.position, "y").min(-3).max(4).step(0.001);
// gui.add(circle.position, "x").min(-3).max(4).step(0.001);
// gui.add(circle.position, "y").min(-3).max(4).step(0.001);
// gui.add(cross.position, "x").min(-3).max(4).step(0.001);
// gui.add(cross.position, "y").min(-3).max(4).step(0.001);
// gui.add(camera.position, "x").min(-3).max(4).step(0.001);
// gui.add(camera.position, "z").min(-3).max(10).step(0.001);
